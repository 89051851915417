import Sorteo from '@guplabs/sorteo-client';
import { Field, Form, Formik } from 'formik';
import map from 'lodash/map';
import { margin, rem } from 'polished';
import React, { Component } from 'react';
import styled from 'styled-components';
import { mixed, object, string } from 'yup';
import { mq } from '../helper/stylehelper';
import { getParameterByName as param, log } from '../helper/utils';
import { colors } from '../helper/variables';
import { Button, FormikCheckbox, FormikInput, FormikSelect, Label } from './form-controls';
import Loader from './loader';
import MessageBox from './message-box';
import { H2 } from './typography';

const FormContainer = styled.div`
    ${margin(rem(30), 0, rem(25))};
    display: flex;
    flex-wrap: wrap;
`;

const FlexLabel = styled(Label)`
    ${margin(0, 0, rem(15), 0)};
    ${({ fullWidth }) => fullWidth && `width: 100% !important;`};
    width: 100%;

    &:last-child {
        margin-bottom: 0;
    }

    ${mq.medium`
        width: calc(50% - 20px);
        ${margin(0, rem(20), rem(20), 0)};
    `};
`;

const A = styled.a`
    color: ${colors.black};
`;

const StyledLoader = styled(Loader)`
    text-align: center;
    max-width: 60px;
`;

const ConfirmLoaderWrapper = styled.div`
    align-items: center;
    display: flex;
`;

export default class ContestForm extends Component {
    static REQUEST_STATES = {
        sending: 'sending',
        success: 'success',
        error: 'error',
    };

    /**
     * Transformiert Fehler von Sorteo in Fehlermeldungen, die Formik nutzen kann
     * @param {Error} error Fehler
     */
    static transformSorteoErrors(error) {
        let sorteoErrors = error;

        if (error.data && error.data.error && error.data.error.data) {
            sorteoErrors = map(
                error.data.error.data,
                sorteoError => (Array.isArray(sorteoError) ? sorteoError.join(' | ') : sorteoError)
            );
        }

        return sorteoErrors;
    }

    /**
     * Box die den Status des Confirms anzeigt (React Ref)
     * @type {Object}
     */
    confirmBox = React.createRef();

    /**
     * Sorteo-Client
     * @type {Sorteo}
     */
    sorteo = null;

    /**
     * State
     * @type {Object}
     */
    state = {
        submitState: '',
        sorteoErrors: null,
        confirmationState: ContestForm.REQUEST_STATES.sending,
        wantsToConfirm: !!(param('e') && param('key')),
    };

    /**
     * Lifecycle Hook bei Mount
     */
    componentDidMount() {
        this.sorteo = new Sorteo(process.env.GATSBY_SORTEO_URL);
        this.sorteo.contestId = process.env.GATSBY_CONTEST;

        const { wantsToConfirm } = this.state;

        if (wantsToConfirm) {
            window.setTimeout(this.scrollToConfirmBox.bind(this), 100);

            this.sorteo.entries
                .confirm(param('e'), param('key'))
                .then(() => {
                    log('DOI success');
                    this.setState({
                        confirmationState: ContestForm.REQUEST_STATES.success,
                    });
                })
                .catch(error => {
                    log('DOI error', error);
                    this.setState({
                        confirmationState: ContestForm.REQUEST_STATES.error,
                        sorteoErrors: ContestForm.transformSorteoErrors(error),
                    });
                });
        }
    }

    /**
     * Handling von Fehlern beim Abschicken des Formulars
     * @param {Object} form FormikBag Objekt
     * @returns {Function}
     */
    handleError = form => error => {
        form.setSubmitting(false);

        this.setState({
            submitState: ContestForm.REQUEST_STATES.error,
            sorteoErrors: ContestForm.transformSorteoErrors(error),
        });

        log('Error sending data', error);
    };

    /**
     * Sendet die Daten an Sorteo
     * @param {Object} values Daten aus Formik
     * @param {Object} form Formik Formular-Actions
     */
    sendDataToSorteo = (values, form) => {
        this.setState({
            submitState: ContestForm.REQUEST_STATES.sending,
        });

        log('Sending data', values);

        this.sorteo.entries
            .store({
                body: {
                    username: values.email,
                    fields: values,
                },
            })
            .then(() => {
                // Alles war erfolgreich!

                form.setSubmitting(false);

                this.setState({
                    submitState: ContestForm.REQUEST_STATES.success,
                });
            })
            .catch(this.handleError(form));
    };

    /**
     * Scrollt zur Messagebox
     */
    scrollToConfirmBox() {
        window.scroll({
            top: this.confirmBox.current.getBoundingClientRect().top + window.scrollY,
            behavior: 'smooth',
        });
    }

    /**
     * Rendert das Formular mit Formik
     */
    render() {
        const { confirmationState, submitState, sorteoErrors, wantsToConfirm } = this.state;

        return (
            <Formik
                initialValues={{
                    salutation: '',
                    firstname: '',
                    lastname: '',
                    email: '',
                    oldEnough: false,
                    terms: false,
                    privacy: false,
                }}
                onSubmit={this.sendDataToSorteo}
                validationSchema={object().shape({
                    salutation: string().oneOf(
                        ['N/A', 'Herr', 'Frau'],
                        'Bitte wähle deine Anrede.'
                    ),
                    firstname: string()
                        .trim()
                        .max(200, 'Bitte kürze deinen Vornamen auf 200 Zeichen.')
                        .required('Bitte gib hier deinen Vornamen ein. '),
                    lastname: string()
                        .trim()
                        .max(200, 'Bitte kürze deinen Nachnamen auf 200 Zeichen.')
                        .required('Bitte gib hier deinen Nachnamen ein.'),
                    email: string()
                        .trim()
                        .email('Bitte gib hier eine gültige E-Mail Adresse ein. ')
                        .required('Bitte gib hier deine E-Mail Adresse ein. '),
                    oldEnough: mixed().oneOf(
                        [true],
                        'Bitte bestätige, dass du über 18 Jahre alt bist.'
                    ),
                    terms: mixed().oneOf([true], 'Du musst den Teilnahmebedingungen zustimmen.'),
                    privacy: mixed().oneOf([true], 'Du musst der Datenschutzerklärung zustimmen.'),
                })}
            >
                {({ isSubmitting, values }) => (
                    <>
                        {wantsToConfirm && (
                            <div ref={this.confirmBox}>
                                {confirmationState === ContestForm.REQUEST_STATES.sending && (
                                    <ConfirmLoaderWrapper>
                                        <StyledLoader />
                                        Teilnahme wird verifiziert...
                                    </ConfirmLoaderWrapper>
                                )}
                                {confirmationState === ContestForm.REQUEST_STATES.success && (
                                    <MessageBox
                                        type="success"
                                        title="Deine Teilnahme ist bestätigt"
                                    >
                                        {`Deine Teilnahme wurde erfolgreich verifiziert. Du nimmst an der Verlosung teil.`}
                                    </MessageBox>
                                )}
                                {confirmationState === ContestForm.REQUEST_STATES.error && (
                                    <MessageBox
                                        type="error"
                                        title="Leider gab es einen Fehler bei der Verifizierung der Teilnahme."
                                    >
                                        {Array.isArray(sorteoErrors)
                                            ? sorteoErrors
                                            : String(sorteoErrors)}
                                    </MessageBox>
                                )}
                            </div>
                        )}
                        <Form>
                            <H2 textCentered>Formular ausfüllen und mit etwas Glück gewinnen</H2>
                            <FormContainer>
                                <FlexLabel>
                                    <Label.Text>Anrede</Label.Text>
                                    <Field name="salutation" component={FormikSelect}>
                                        <option value="N/A">Bitte wählen</option>
                                        <option value="Frau">Frau</option>
                                        <option value="Herr">Herr</option>
                                    </Field>
                                </FlexLabel>
                                <FlexLabel>
                                    <Label.Text>Vorname *</Label.Text>
                                    <Field type="text" name="firstname" component={FormikInput} />
                                </FlexLabel>
                                <FlexLabel>
                                    <Label.Text>Nachname *</Label.Text>
                                    <Field type="text" name="lastname" component={FormikInput} />
                                </FlexLabel>
                                <FlexLabel>
                                    <Label.Text>E-Mail *</Label.Text>
                                    <Field type="email" name="email" component={FormikInput} />
                                </FlexLabel>
                                <FlexLabel gap="l" fullWidth>
                                    <Field
                                        type="checkbox"
                                        name="oldEnough"
                                        component={FormikCheckbox}
                                        label={
                                            <Label.CheckboxText>
                                                Ich bin älter als 18 Jahre *
                                            </Label.CheckboxText>
                                        }
                                    />
                                </FlexLabel>
                                <FlexLabel gap="l" fullWidth>
                                    <Field
                                        type="checkbox"
                                        name="terms"
                                        component={FormikCheckbox}
                                        label={
                                            <Label.CheckboxText>
                                                Ich habe die{' '}
                                                <A href="/teilnahmebedingungen/" target="_blank">
                                                    Teilnahmebedingungen
                                                </A>{' '}
                                                gelesen und erkläre mich damit einverstanden *
                                            </Label.CheckboxText>
                                        }
                                    />
                                </FlexLabel>
                                <FlexLabel fullWidth>
                                    <Field
                                        type="checkbox"
                                        name="privacy"
                                        component={FormikCheckbox}
                                        label={
                                            <Label.CheckboxText>
                                                Ich habe die{' '}
                                                <A href="/datenschutz/" target="_blank">
                                                    Datenschutzerklärung
                                                </A>{' '}
                                                gelesen und erkläre mich damit einverstanden *
                                            </Label.CheckboxText>
                                        }
                                    />
                                </FlexLabel>
                                <FlexLabel as="p" fullWidth>
                                    * = Pflichtfeld
                                </FlexLabel>
                            </FormContainer>

                            {submitState !== ContestForm.REQUEST_STATES.success && (
                                <Button type="submit" disabled={isSubmitting}>
                                    Jetzt teilnehmen!
                                </Button>
                            )}

                            {submitState === ContestForm.REQUEST_STATES.sending && <StyledLoader />}
                            {submitState === ContestForm.REQUEST_STATES.success && (
                                <MessageBox type="success" title="Vielen Dank für deine Teilnahme!">
                                    {`Wir haben deine Daten erfolgreich gespeichert. Um deine Teilnahme
                                        zu verifizieren, erhältst du in den nächsten Minuten eine Email
                                        an ${
                                            values.email
                                        }. Bitte verifiziere deine Teilnahme in dem du
                                        auf den Link klickst.`}
                                </MessageBox>
                            )}
                            {submitState === ContestForm.REQUEST_STATES.error && (
                                <MessageBox type="error" title="Leider gab es einen Fehler">
                                    {Array.isArray(sorteoErrors)
                                        ? sorteoErrors
                                        : String(sorteoErrors)}
                                </MessageBox>
                            )}
                        </Form>
                    </>
                )}
            </Formik>
        );
    }
}
