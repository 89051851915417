import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba, position } from 'polished';
import { fluidImageType } from '../helper/prop-types';
import { fontSizes, colors, fontWeights } from '../helper/variables';
import { mq } from '../helper/stylehelper';

const Wrapper = styled.div`
    position: relative;
    z-index: -1;
`;

const Title = styled.h1`
    background-color: ${colors.blueDark};
    color: white;
    font-size: ${fontSizes.l};
    font-weight: ${fontWeights.bold};
    margin: 0;
    padding: 0.25em 0.5em;
    text-align: center;

    ${mq.medium`
        ${position('absolute', null, null, '5%', '50%')};
        background-color: ${rgba(colors.blueDark, 0.9)};
        font-size: ${fontSizes.xl};
        transform: translate(-50%, -10%);
    `};
    ${mq.large`
        font-size: ${fontSizes.xxl};
    `};
    ${mq.xlarge`
        font-size: ${fontSizes.xxxl};
    `};
`;

const Hero = ({ image, title }) => (
    <Wrapper>
        <Img fluid={image} />
        {!!title && <Title>{title}</Title>}
    </Wrapper>
);

Hero.propTypes = {
    image: fluidImageType.isRequired,
    title: PropTypes.string,
};

Hero.defaultProps = {
    title: null,
};

export default Hero;
