import { ErrorMessage } from 'formik';
import React from 'react';
import { padding, rem } from 'polished';
import styled, { css } from 'styled-components';
import { mq, gapable } from '../helper/stylehelper';
import { colors, fontSizes, fontWeights } from '../helper/variables';

export const Button = styled.button`
    ${padding(rem(10), rem(15))};
    background-color: transparent;
    font-size: ${fontSizes.m};
    border: 3px solid ${colors.blueDark};
    color: ${colors.blueDark};
    cursor: pointer;
    display: block;
    font-weight: ${fontWeights.bold};
    transition: 0.2s;
    width: 100%;

    ${mq.medium`
        width: auto;
    `};

    ${mq.medium`
        font-size: ${fontSizes.l};
    `};

    &:hover,
    &:focus {
        background: ${colors.blueLight};
        border-color: ${colors.blueLight};
        color: ${colors.white};
    }

    &[disabled] {
        background: ${colors.grayDark};
        border-color: ${colors.grayDark};
        color: ${colors.white};
        cursor: not-allowed;
    }
`;

const commonInputStyles = css`
    background: ${colors.white};
    border: none;
    border-radius: 2px;
    border-bottom: 1px solid ${colors.grayLight};
    color: ${colors.black};
    ${padding(rem(10), rem(15))};
    width: 100%;
`;

export const Input = styled.input`
    ${commonInputStyles};
    /* height: ${rem(30)}; */
`;

export const Checkbox = styled.input.attrs({
    type: 'checkbox',
})`
    float: left;
    margin-top: ${rem(3)};
`;

export const Select = styled.select`
    ${commonInputStyles};
    height: ${rem(39)};
`;

export const Label = styled.label`
    display: block;
    font-size: ${fontSizes.s};
    font-weight: ${fontWeights.bold};
    ${gapable('none')};
`;

Label.Text = styled.span`
    display: block;
    margin-bottom: ${rem(5)};
`;

Label.CheckboxText = styled.span`
    display: block;
    margin-left: ${rem(30)};
`;

const ErrorText = styled.div`
    color: ${colors.red};
    margin-top: ${rem(5)};
`;

export const FormikInput = ({ field, form, ...props }) => (
    <>
        <Input {...field} {...props} />
        <ErrorMessage name={field.name} component={ErrorText} />
    </>
);

export const FormikCheckbox = ({ field, form: { values }, label, ...props }) => (
    <>
        <Checkbox {...field} {...props} checked={values[field.name]} />
        {label}
        <ErrorMessage name={field.name} component={ErrorText} />
    </>
);

export const FormikSelect = ({ field, children }) => (
    <>
        <Select {...field}>{children}</Select>
        <ErrorMessage name={field.name} component={ErrorText} />
    </>
);
