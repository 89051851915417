import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" className={className}>
        <path
            opacity=".5"
            fill="#2A6AB7"
            d="M20.2 5.17c-8.253 0-14.945 6.69-14.945 14.945 0 8.255 6.692 14.946 14.946 14.946s14.947-6.69 14.947-14.945c0-8.254-6.692-14.946-14.946-14.946zm0 26.58c-6.424 0-11.633-5.21-11.633-11.635S13.777 8.48 20.2 8.48c6.426 0 11.634 5.21 11.634 11.635 0 6.426-5.208 11.634-11.633 11.634z"
        />
        <path
            fill="#2A6AB7"
            d="M9.473 15.06L6.45 13.715a14.854 14.854 0 0 0-1.22 7.635l3.293-.346c-.22-2.107.144-4.138.95-5.944z"
        >
            <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 20 20"
                to="360 20 20"
                dur="0.8s"
                repeatCount="indefinite"
            />
        </path>
    </svg>
);

Loader.propTypes = {
    className: PropTypes.string,
};

Loader.defaultProps = {
    className: '',
};

export default Loader;
