import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import isFuture from 'date-fns/is_future';
import Layout from '../components/layout';
import Hero from '../components/hero';
import { H2, P } from '../components/typography';
import Container from '../components/container';
import Section from '../components/section';
import ContestForm from '../components/contest-form';
import { fluidImageType } from '../helper/prop-types';

const WiesnPage = ({ data }) => (
    <Layout
        conclusionBoxes={['aboutus', 'recipes']}
        title="Wiesn-Gewinnspiel 2019"
        description="Wir machen Wiesn-Träume wahr: Unter allen Teilnehmer verlosen wir einen exklusiven Abend auf dem Münchner Oktoberfest 2019 inkl. Anreise und Übernachtung."
    >
        <Hero height="50vh" image={data.file.childImageSharp.fluid} />
        <Section>
            <Container maxWidth="small" padding="0">
                <H2 textCentered>Gewinne einen Abend auf der Wiesn 2019</H2>
                <P textCentered intro>
                    Unter allen Teilnehmern verlosen wir einen exklusiven Abend auf dem Oktoberfest
                    für zwei Personen in einem der großen Festzelte und Bezuschussung der
                    Fahrtkosten.
                    <br />
                    Teilnahmeschluss 20.09.2019.
                    <br />
                    <strong>Viel Glück!</strong>
                </P>
            </Container>
        </Section>
        <Section>
            <Container maxWidth="small" padding="0">
                {/* Teilnahmeschluss am 12.07. um 22 Uhr -- Monat Einstellung: 0 = Januar */}
                {isFuture(new Date(2019, 8, 20, 22, 0, 0)) ? (
                    <ContestForm />
                ) : (
                    <P>Das Gewinnspiel ist beendet. Vielen Dank an die zahlreichen Teilnehmer!</P>
                )}
            </Container>
        </Section>
    </Layout>
);

WiesnPage.propTypes = {
    data: PropTypes.shape({
        file: fluidImageType,
    }).isRequired,
};

export default WiesnPage;

export const query = graphql`
    query {
        file(relativePath: { eq: "keyvisual/keyvisual-wiesn.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
    }
`;
